import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import { fetchWithAuth } from '../../../utils/api';

type Thread = {
    id: number;
    updated: string;
    title: string;
};

const getDailyChatHistory = function (threads: Thread[]) {
    // Group threads by date
    const threadsByDate = threads.reduce((acc: { [key: string]: Thread[] }, thread) => {
        const date = new Date(thread.updated).toLocaleDateString();
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(thread);
        return acc;
    }, {});

    // Sort threads within each date group by timestamp in descending order
    Object.keys(threadsByDate).forEach((date) => {
        threadsByDate[date].sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
    });

    // Sort dates in descending order using ISO dates for sorting
    const sortedDates = Object.keys(threadsByDate).sort((a, b) => {
        const isoDateA = threadsByDate[a][0].updated;
        const isoDateB = threadsByDate[b][0].updated;
        return new Date(isoDateB).getTime() - new Date(isoDateA).getTime();
    });

    return { dates: sortedDates, threadsByDate };
};

export function ChatHistoryDrawer({ isDrawerOpen }: { isDrawerOpen: boolean }) {
    const { data: threads = [], isLoading: isLoadingThreads } = useQuery<Thread[]>('threads', async () => {
        const api = `${BASE_API_URL}/threads`;
        const response = await fetchWithAuth(api);

        if (!response.ok) throw new Error('Failed to fetch threads');
        return response.json();
    });

    const { dates, threadsByDate } = getDailyChatHistory(threads);

    return (
        <Drawer anchor='left' open={isDrawerOpen} variant={isDrawerOpen ? 'persistent' : 'temporary'}>
            <Box sx={{ width: 250, marginTop: '70px' }} role='presentation'>
                <List
                    sx={{
                        width: '100%',
                        maxHeight: 'calc(100vh - 70px)',
                        overflow: 'auto',
                        maxWidth: '250px',
                        textOverflow: 'ellipsis',
                    }}>
                    <ListItem
                        disablePadding
                        component='li'
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            component={NavLink}
                            to={`/`}
                            title='New chat'
                            sx={{ flexGrow: 1, color: 'text.primary' }}
                            startIcon={<QuestionAnswerOutlinedIcon />}
                            color='primary'>
                            New Chat
                        </Button>
                        <Button
                            onClick={() => queryClient.invalidateQueries('threads')}
                            title='Refresh chat history'
                            startIcon={<RefreshIcon />}
                            color='primary'
                            sx={{ color: 'text.primary' }}></Button>
                    </ListItem>
                    <Divider />
                    {isLoadingThreads ? (
                        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
                            <CircularProgress />
                        </Box>
                    ) : (
                        dates.map((date) => (
                            <React.Fragment key={date}>
                                <ListItem>
                                    <Typography variant='body2' fontWeight='bold'>
                                        {date}
                                    </Typography>
                                </ListItem>
                                {threadsByDate[date].map((thread) => (
                                    <ListItem key={thread.id} disablePadding component='li' title={thread.title}>
                                        <ListItemButton
                                            component={NavLink}
                                            to={`/${thread.id}`}
                                            sx={{
                                                '&.active': {
                                                    backgroundColor: '#e0e0e0',
                                                },
                                            }}>
                                            <Box
                                                sx={{
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}>
                                                <Box
                                                    sx={{
                                                        maxWidth: '200px',
                                                        flexShrink: 1,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}>
                                                    {thread.title}
                                                </Box>
                                                <Box sx={{ width: '55px', flexShrink: 0, textAlign: 'right' }}>
                                                    {new Date(thread.updated).toLocaleString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })}
                                                </Box>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                <Divider />
                            </React.Fragment>
                        ))
                    )}
                </List>
            </Box>
        </Drawer>
    );
}
