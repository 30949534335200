import { useAuthenticator } from '@aws-amplify/ui-react';
import { ETFMenu } from '@cfra-nextgen-frontend/shared';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { HorizontalPagePaddingString, MaxPageWidthString } from '@cfra-nextgen-frontend/shared/src/utils';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Breakpoint, ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextLogo from 'components/TextLogo/TextLogo';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageNames } from 'utils/enums';
import { UsageSummary } from 'features/usageSummary';
import MenuIcon from '@mui/icons-material/Menu';
import HelpModal from 'components/HelpModal/HelpModal';

const DefaultLandingPage: { name: string; href: string } = {
    name: PageNames.Home,
    href: '/',
};

export function TopNavigation({
    topNavigationContentHeight,
    toggleDrawer,
}: {
    topNavigationContentHeight: number;
    toggleDrawer: () => void;
}) {
    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
    const navigate = useNavigate();
    const isScreenDisplay = useMediaQuery('screen');

    const theme = createTheme(breakpointsTheme as ThemeOptions, {
        components: {
            MuiAppBar: {
                defaultProps: {
                    position: isScreenDisplay ? 'sticky' : 'static',
                },
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#FFF',
                    },
                    root: {
                        borderBottom: 'solid #E4E5E9 1px',
                        borderTop: 'solid #3078B5 4px',
                        boxShadow: 'none',
                        zIndex: 1500,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#3078B5',
                        fontSize: 18,
                        fontFamily: fontFamilies.GraphikLight,
                        height: 48,
                        textTransform: 'uppercase',
                        width: 48,
                    },
                },
            },
            MuiContainer: {
                defaultProps: {
                    disableGutters: true,
                    maxWidth: MaxPageWidthString as Breakpoint,
                },
                styleOverrides: {
                    root: {
                        maxWidth: MaxPageWidthString,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        marginLeft: '18px',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: '#3078B5',
                        },
                        color: '#0B2958',
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '15px',
                        lineHeight: 1,
                        marginRight: '28px',
                        minWidth: '0px',
                        padding: 0,
                        paddingBottom: '25px',
                        paddingTop: '25px',
                        textTransform: 'none',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        background: '#4375BB',
                        borderRadius: '5px',
                        height: '4px',
                    },
                    root: {
                        [breakpointsTheme.breakpoints.down('lg')]: {
                            visibility: 'collapse',
                            width: 0,
                        },
                        paddingTop: '3px',
                    },
                },
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                },
                styleOverrides: {
                    root: {
                        paddingLeft: HorizontalPagePaddingString,
                        paddingRight: HorizontalPagePaddingString,
                        display: 'flex',
                        height: topNavigationContentHeight,
                        justifyContent: 'space-between',
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    mr: 1,
                    noWrap: true,
                    variant: 'h6',
                },
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        fontSize: '28px',
                        fontFamily: fontFamilies.GraphikLight,
                        lineHeight: 1.2,
                        textDecoration: 'none',
                    },
                },
            },
        },
    });
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const cfraLogoStyles = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar>
                <Container>
                    <Toolbar>
                        <Button
                            onClick={toggleDrawer}
                            sx={{
                                marginLeft: 0,
                                paddingRight: '18px',
                                [theme.breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}>
                            <MenuIcon />
                        </Button>
                        <Box
                            data-testid='cfra-logo'
                            sx={cfraLogoStyles}
                            onClick={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: 'logo',
                                });
                                navigate(DefaultLandingPage.href);
                            }}>
                            <TextLogo fontSize={isMediumScreen ? 40 : 28} />
                        </Box>
                        <div style={{ flexGrow: 1 }} />
                        <UsageSummary labelText={isMediumScreen ? 'Monthly Usage: ' : ''} />    
                        <Box display="flex" alignItems="center" title="Help" sx={{marginLeft: "15px"}}>
                            <HelpModal buttonFontSize={25}></HelpModal>
                        </Box>
                        <ETFMenu.ETFMenu
                            menuItems={[
                                {
                                    itemName: user?.username || '',
                                },
                                {
                                    itemName: 'Logout',
                                    callback: () => {
                                        globalThis.analytics?.registerAction?.({
                                            action: 'avatar : logout',
                                        });
                                        signOut();
                                    },
                                },
                            ]}>
                            <Tooltip title={user?.username || ''}>
                                <IconButton>
                                    <Avatar>{user?.username?.[0]}</Avatar>
                                </IconButton>
                            </Tooltip>
                        </ETFMenu.ETFMenu>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}

export default TopNavigation;
