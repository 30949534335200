import { Button } from '../Button';

export default function ErrorFallbackVPN() {
    return (
        <div>
            <h2>Oops, something went wrong :(</h2>
            <span>Please ensure that you are connected to VPN.</span>
            <Button onClick={() => window.location.assign(window.location.origin)}>Refresh</Button>
        </div>
    );
}
