import { ETFInfo } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFInfo/ETFInfo';

export default function HelpModal({
    buttonFontSize = 20,
}: {
    buttonFontSize?: number;
}) {
    const title = 'Help';
    const description = (
        <>
            <h3>AI Policy</h3>
            <p>CFRA Copilot tool provides a chat interface with LLMs through a secure integration that protects proprietary information. Please refer to the AI policy in the employee handbook for further guidance.</p>
            <a
                href={`https://cfraresearch.sharepoint.com/sites/CFRAEmployeeAccess/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCFRAEmployeeAccess%2FShared%20Documents%2FEmployee%20Policies%2C%20Holidays%2C%20etc%2FEmployee%20Handbooks`}
                target={'_blank'}
                rel={'noreferrer'}
                style={{
                    color: '#007bb8',
                    textDecoration: 'none',
                }}>
                Click here to access the employee handbook.
            </a>
            <h3>Models</h3>
            <p>
                Three model sizes are available for interaction: Large, Medium and Small. Larger models are typically more capable of handling difficult tasks and produce higher-quality responses, but they also take longer to respond and cost more. Please choose a model size based on the difficulty of the task. For example, to extract metrics from a document, a small model may perform adequately, whereas a large model may be required for extracting ideas/insights from a large document.
            </p>
            <h3>Tools</h3>
            <p>
                The tools dropdown contains a list of pre-built "prompts". These prompts have been tested for some of the common tasks supported by the models. For example, to summarize ENRN into USRN, you may choose the appropriate tool for it and supply the ENRN text to the models for summarization. You may chat with the models to refine the output.
            </p>
        </>
    );

    return (
        <ETFInfo
            title={title}
            titleStyle={{
                boxShadow: "none"
            }}
            description={description}
            buttonFontSize={buttonFontSize}
        />
    );
}
